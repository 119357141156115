<template>
  <div class="height-100">
    <!-- 导航 -->
    <loading v-if="loading"></loading>
    <div>
      <van-tabs v-model="active" animated @click="onClick">
        <van-tab v-for="(item, index) in title" :title="item" :name="index" :key="index">
          <div v-if="!loading">
            <div v-if="list.length > 0">
              <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list v-if="list.length > 0" v-model="loading2" :finished="finished" finished-text="没有更多了"
                  @load="onLoad">
                  <caka-order :list="list" @onCancelOrder="showDialog" @goPay="changePay" @Sureget="sureget"
                    @Logistics="goLogistics">
                  </caka-order>
                </van-list>
              </van-pull-refresh>
            </div>
            <van-empty description="暂无订单" v-if="showEmpty" />
            <!-- <div class="empt" v-else> -->
            <!-- <img src="@/assets/images/logo_loading.png">
              <span style="margin-top: 1rem;">暂无订单</span> -->
            <!-- </div> -->
          </div>
        </van-tab>
      </van-tabs>
      <Translate v-if="showWL" :logistics-list="logisticsList" @closed="closedadd" :active="(logisticsList.length - 1)">
      </Translate>
    </div>
  </div>
</template>

<script>
import CakaOrder from "../../components/order/CakaOrder.vue";
let initViewfinish = false
export default {
  name: "",
  props: {},
  data() {
    return {
      isLoading: false,
      showEmpty: false,
      loading: true,
      loading2: false,
      finished: false,
      logisticsList: null,
      list: [
      ],
      title: ["全部订单", "待支付", "待收货", "已完成"],
      form: {
        status: '',
        current: 1,
        size: 10,
        userId: '',
      },
      active: 1,
      showWL: false,
      userId: null,
    };
  },
  components: { CakaOrder },
  created() {
    if (initViewfinish) {
      initViewfinish = false
      this.initView()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    }
  },
  methods: {
    closedadd(val) {
      this.showWL = val
    },
    goLogistics(index) {
      let item = this.list[index]
      if (item.tripartiteOrderNo) {
        let form = {
          logisticCode: item.express_no,
          // logisticCode: '8152049085401',
          shipperCode: item.expressCode,
          customerName: item.phone,
          orderCode: this.orderNo,
        }
        this.$api
          .queryEbusiness(form)
          .then((res) => {
            if (res.code == 1) {
              this.$Toast(res.msg);
            } else {
              this.logisticsList = res.data.Traces
              this.showWL = true
            }

          })
          .catch((err) => {
            this.$Toast(err.msg);
          });
      }

    },
    sureget(index) {
      let item = this.list[index]
      this.$api
        .cakeConfirmReceipt(item.orderNo, item.productSubOrderList[0].id)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.$Toast(res.msg);
            this.onRefresh()
          }
        })
        .catch((err) => {
          this.$Toast(err.msg);
        });

    },
    changePay(index) {
      let item = this.list[index]
      // if (item.extraPay) {
      //   this.weiPay(item)
      // } else {
      this.toPay(item)
      // }
    },
    // this.cakaOrder = JSON.parse(localStorage.getItem('gooddetail'))
    //this.paytype = this.$route.query.type
    toPay(item) {
      let cardId = item.orderDetail ? JSON.parse(item.orderDetail).cardId : item.cardId
      if (item.orderDetail) {
        let entrCode = localStorage.getItem('entrCode')
        if (entrCode == 'E3') {
          localStorage.setItem('film_id', item.productId)
          let seatList = []
          item.productSubOrderList.forEach(element => {
            let seat = {
              seatId: element.seatId,
              price: element.showPrice
            }
            seatList.push(seat)

          });
          localStorage.setItem("seatList", JSON.stringify(seatList))
          localStorage.setItem("sale_price", item.productTotal)
          this.$router.push({
            path: "/MovieCashier",
            query: {
              order: item.tripartiteOrderNo,
              creatTime: item.createAt,
              cardId: cardId
            },
          });
        } else {
          localStorage.setItem('film_id', item.productId)
          let seatList = []
          item.productSubOrderList.forEach(element => {
            let seat = {
              seatId: element.seatId,
              price: element.showPrice
            }
            seatList.push(seat)

          });
          console.log('seatList  ', seatList)
          localStorage.setItem("seatList", JSON.stringify(seatList))
          localStorage.setItem("sale_price", item.productTotal)
          this.$router.push({
            path: "/Cashier",
            query: {
              order: item.tripartiteOrderNo,
              creatTime: item.createAt,
              cardId: cardId
            },
          });
        }
      } else {
        let costPrice = 0
        item.productSubOrderList.forEach(element => {
          costPrice += element.costPrice
        });
        item.costPrice = costPrice
        localStorage.setItem('gooddetail', JSON.stringify(item))
        if (item.upgradeFlag === '0' && item.bindEntrance === 'E3') {
          this.$router.push({
            path: "/MovieForGoodsCashier",
            query: {
              order: item.orderNo,
              creatTime: item.createAt,
              cardId: cardId
            },
          });
        } else
          this.$router.push({
            path: "/Cashier",
            query: {
              type: 'zy-shop',
              order: item.orderNo,
              creatTime: item.createAt,
              cardId: cardId
            },
          });
      }
    },
    weiPay(item) {
      let form = {
        amount: item.extraPay,
        userId: this.userId,
        channelOrderNo: item.orderDetail ? item.tripartiteOrderNo : item.orderNo
      }
      this.$api
        .generatePayOrderInfo(form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.init(res.data)
          }

        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    directWeChat(params) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,     //公众号ID，由商户传入     
        "timeStamp": params.timeStamp,        //时间戳，自1970年以来的秒数     
        "nonceStr": params.nonceStr,//随机串     
        "package": params.package,
        "signType": params.signType,       //微信签名方式：     
        "paySign": params.paySign, //微信签名 
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$Toast('支付成功');
            this.onRefresh();
          }
        });
    },
    init(params) {
      console.log('params', params)
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.directWeChat(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.directWeChat(params))
          document.attachEvent('onWeixinJSBridgeReady', this.directWeChat(params))
        }
      } else {
        this.directWeChat(params)
      }
    },

    showDialog(val) {

      this.$Dialog.confirm({
        title: "提示",
        message: "是否确认取消订单？",
        confirmButtonText: '我在想想',
        cancelButtonText: '取消订单'
      })
        .then(() => {
        })
        .catch(() => {
          // on cancel
          this.cancelPayment(val)
        });
    },
    cancelPayment(val) {
      let item = this.list[val]
      if (item.orderDetail) {
        this.$api
          .cancelPayment(item.tripartiteOrderNo)
          .then((res) => {
            if (res.code == 1) {
              this.$Toast(res.msg);
            } else {
              this.$Toast(res.msg);
              this.onRefresh()
            }

          })
          .catch((err) => {
            this.$Toast(err.msg);
          });

      } else
        this.$api
          .productcancelPayment(item.orderNo)
          .then((res) => {
            if (res.code == 1) {
              this.$Toast(res.msg);
            } else {
              this.$Toast(res.msg);
              this.onRefresh()
            }

          })
          .catch((err) => {
            this.$Toast(err.msg);
          });
    },
    //	0待支付 1待发货 2已发货 3已完成 4已关闭 5超时
    onClick() {
      if (this.active == 0) {
        this.form.status = ''
      } else if (this.active == 1) {
        this.form.status = '0'
      } else if (this.active == 2) {
        this.form.status = '10'
      } else {
        this.form.status = '3'
      }
      this.list.length = 0
      this.form.current = 1
      this.loading = true
      this.finished = false,
        this.getMyOrder()

    },
    onRefresh() {
      this.form.current = 1
      this.getMyOrder()
    },
    onLoad() {
      this.form.current = this.form.current + 1
      this.getMyOrder()
    },
    // //   获取订单信息
    getMyOrder() {
      this.$api
        .queryOrderInfo(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          if (this.form.current == 1) {
            this.list = res.data.records
          } else {
            this.list.push(...res.data.records)
          }
          if (this.form.current >= res.data.pages) {
            this.finished = true
          }

          res.data.records.length ? this.showEmpty = false : this.showEmpty = true
          this.loading = false
          this.isLoading = false
          this.loading2 = false
        })
        .catch((err) => {
          this.loading2 = false
          this.finished = true
          this.loading = false
          this.isLoading = false
        });
    },
    initView() {
      this.form.userId = JSON.parse(localStorage.getItem("userInfo")).id
      this.userId = this.form.userId
      this.active = Number(this.$route.query.active) ? Number(this.$route.query.active) : 0
      if (this.active == 0) {
        this.form.status = ''
      } else if (this.active == 1) {
        this.form.status = '0'
      } else if (this.active == 2) {
        this.form.status = '10'
      } else {
        this.form.status = '3'
      }
      this.getMyOrder();
    }
  },

  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = true
    if (from.path !== "/OrderDetail" && from.path !== '/CakaOrderDetail') {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      initViewfinish = true
      next();
    }
  },
  // //详情页获取参数
  // beforeRouteLeave(to, from, next) {
  //   // console.log('path',to.path )
  //   if (to.path === '/OrderDetail' || to.path === '/CakaOrderDetail') {
  //     from.meta.keepAlive = true
  //   } else {
  //     from.meta.keepAlive = false
  //   }
  //   next()
  // },
  mounted() {
    // 

  },

  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.empt {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #000;
}
</style>